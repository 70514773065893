.main{
  display: flex;
}
.glossaryTitle{
font-family: "Rubik";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 54px */
margin-bottom: 48px;
width: 100%;
}
.trendingPostTitle{
  color: #26262C;
font-family: Rubik;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}
.left{
    width: 70%;
    margin-right: 48px;
}
.right{
    width: 30%;
}
.filter{
    display: flex;
    justify-content: space-between;
    padding-bottom: 36px;
    border-bottom: 2px solid #EAEAEA;
}
.sortby{
    display: flex;
    align-items: center;
}
.sortby > p{
    color: var(--Grey-10, #26262C);
font-family: "Lato";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 30px */
letter-spacing: 0.2px;
margin-right: 16px;
}
.select{
  width: 200px;
}
.searchBar {
  padding: 12px 24px;
  display: flex;
  border-radius: 36px;
  background: #f6f6f6;
  width: 30vw;
}
.searchBar > input {
  outline: none;
  border: none;
  background-color: transparent;
  margin-left: 12px;
  width: 28vw;
}
.searchBar > input ::placeholder {
  color: var(--grey-20, #4c4b55);
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: 0.18px;
}
.glossaryItem{
  padding-bottom: 36px;
  margin-bottom: 36px;
  border-bottom: 2px solid #EAEAEA;
}
.itemTitle{
  font-family: "Lato";
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 30px */
letter-spacing: 0.2px;
margin-bottom: 12px;
}
.itemText{
  color: var(--Grey-20, #4C4B55);
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  margin-bottom: 12px;
}
.itemRelated > a{
  color: var(--Link-Blue, #1652C7);
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
letter-spacing: 0.16px;
text-decoration-line: underline;
margin-left: 8px;
}
.eventsPagination {
  margin-top: 48px;
  margin-bottom: 64px;
}
.prevButton {
  margin-right: auto;
  font-weight: 600;
}
.nextButton {
  margin-left: auto;
  font-weight: 600;
}
.sortIcon{
  background-color: #EAEFF2;
  border-radius: 200px;
  padding: 6px;
justify-content: center;
align-items: center;
margin-right: 8px;
display: none;
}
@media (max-width : 1185px) {
  .left{
    width: 65%;
  }
  .right{
    width: 35%;
  }
}
@media (max-width : 1100px) {
  .right{
    display: none;
  }
  .left{
    width: 100%;
    margin-right: 0px;
  }
}
@media (max-width : 680px) {
  .glossaryTitle{
    font-family: "Rubik";
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 150%;
margin-bottom: 24px;
  }
  .filter{
    flex-direction: column-reverse;
  }
  .searchBar{
    width: 86%;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  .searchBar > input{
    width: 86%;
  }
  .sortby > p{
    display: none;
  }
  .sortIcon{
    display: flex;
  }
}