.homearticles {
  padding: 15px 30px;
  margin-top: 20px;
}
.articlesHeading {
  font-family: "Rubik";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 54px */
  margin-bottom: 15px;
}
.main {
  display: flex;
  justify-content: space-between;
}
.articleSection {
      display: flex;
    width: auto;
    gap: 21px;
    height: min-content;
    cursor: pointer;
    padding: 0;
}
.articleSectionHeading {
  font-family: "Rubik";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
  padding: 10px 0;

  color: #000;
}
.articleSectionHeading > span {
  padding: 13.9px 0;
}

.cardText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.cardHeading {
  font-family: "Lato";

  margin-top: 10px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}
.postInfo ul {
  gap: 10px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 30px;
}
.postInfo ul li:first-child {
  list-style: none !important;
}

.postInfo ul li {
  list-style: disc;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
}

.cardTimeImage {
  margin-right: 8px;
}

.subArticle {
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}
.subarticleHeading {
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  letter-spacing: 0.2px;
}
.reform {
  padding: 15px 5px;
}
.timeContainer {
  display: flex;
  align-items: center;
  
    margin-left: -20px;
}
.cardImageContainer {
  position: relative;
}
.cardImageContainer img {
  border-radius: 5px;
  width: 200px;
}
.cardImageContainer .postTags {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
}
.cardImageContainer .postTags .postTag {
  background: #fff;
  color: #1652c7;
  font-family: "Lato";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 20px;
  border-radius: 20px;
}
@media (max-width : 1024px) {
  .articleSection{
    padding-bottom: 12px;
    margin-bottom: 12px ;
    
    border-bottom: 0;
  }
}
@media screen and (max-width: 992px) {
  .articleSection {
       display: flex;
    align-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    flex-direction: row-reverse;
    justify-content: space-between;
        padding-bottom: 12px;
  }
  .articleSection img {
    width: 16px;
  }
  .cardHeading {
    font-size: 16px;
    font-weight: 400;
  }
  .timeContainer {
    display: none;
  }
  .postInfo ul {
    margin-top: 0px;
  }
}
/* Responsive for mobile , tab , desktop */
@media (max-width: 992px) {
  .container {
    padding: 0 20px;
  }
  .recentPost {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    justify-content: space-between;
    margin-bottom: 193px;
  }
  .PostsSec {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    width: 100%;
  }
  .recentPostContainer {
    padding-bottom: 36px;
  }
  .sideCardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .articleSectionHeading {
    font-family: "Rubik";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    padding: 10px 0;
    border-bottom: 2px solid #dcdfe0;
    color: #394250;
  }
  .articleSectionHeading > span {
    padding: 13.9px 0;
  }
  .cardText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .cardHeading {
    font-family: "Lato";
    margin-top: 10px;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
  }
  .postInfo ul {
    gap: 10px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 30px;
  }
  .postInfo ul li:first-child {
    list-style: none !important;
  }
  .postInfo ul li {
    list-style: disc;
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
  }
  .cardTimeImage {
    margin-right: 8px;
  }
  .subArticle {
    border-bottom: 1px solid #eaeaea;
    padding: 10px 0;
  }
  .subarticleHeading {
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.2px;
  }
  .reform {
    padding: 15px 5px;
  }
  .timeContainer {
    display: flex;
    align-items: center;
  }
  .cardImageContainer {
    position: relative;
  }
  .cardImageContainer img {
    border-radius: 5px;
    width: 200px;
  }
  .cardImageContainer .postTags {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    gap: 10px;
  }
  .cardImageContainer .postTags .postTag {
    background: #fff;
    color: #1652c7;
    font-family: "Lato";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 20px;
    border-radius: 20px;
  }
  .eventsPagination {
    margin-bottom: 83px;
  }
  :root {
    --pagination-color: var(--primary-color);
    --pagination-font-family: "Lato";
    --pagination-font-size: 18px;
    --pagination-active-bg: var(--primary-color);
  }
  .prevButton {
    margin-right: auto;
    font-weight: 600;
  }
  .nextButton {
    margin-left: auto;
    font-weight: 600;
  }
}
.postTag {
    background: #fff;
    color: #1652c7;
    font-family: "Lato";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
  }
@media (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
  .recentPost {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    justify-content: space-between;
    margin-bottom: 193px;
  }
  .PostsSec {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    width: 100%;
  }
  .recentPostContainer {
    padding-bottom: 36px;
  }
  .sideCardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .articleSectionHeading {
    font-family: "Rubik";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    padding: 10px 0;
    border-bottom: 2px solid #dcdfe0;
    color: #394250;
  }
  .articleSectionHeading > span {
    padding: 13.9px 0;
  }
  .cardText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .cardHeading {
    font-family: "Lato";
    margin-top: 10px;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
  }
  .postInfo ul {
    gap: 10px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 30px;
  }
  .postInfo ul li:first-child {
    list-style: none !important;
  }
  .postInfo ul li {
    list-style: disc;
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
  }
  .cardTimeImage {
    margin-right: 8px;
  }
  .subArticle {
    border-bottom: 1px solid #eaeaea;
    padding: 10px 0;
  }
  .subarticleHeading {
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.2px;
  }
  .reform {
    padding: 15px 5px;
  }
  .timeContainer {
    display: flex;
    align-items: center;
  }
  .cardImageContainer {
    position: relative;
  }
  .cardImageContainer img {
    border-radius: 5px;
    width: 200px;
  }
  .cardImageContainer .postTags {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    gap: 10px;
  }
  .cardImageContainer .postTags .postTag {
    background: #fff;
    color: #1652c7;
    font-family: "Lato";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 20px;
    border-radius: 20px;
  }
  .eventsPagination {
    margin-bottom: 83px;
  }
  :root {
    --pagination-color: var(--primary-color);
    --pagination-font-family: "Lato";
    --pagination-font-size: 18px;
    --pagination-active-bg: var(--primary-color);
  }
  .prevButton {
    margin-right: auto;
    font-weight: 600;
  }
  .nextButton {
    margin-left: auto;
    font-weight: 600;
  }
}
@media (max-width: 576px) {
  .container {
    padding: 0 20px;
  }
  .recentPost {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    justify-content: space-between;
    margin-bottom: 193px;
  }
  .PostsSec {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    width: 100%;
  }
  .recentPostContainer {
    padding-bottom: 36px;
  }
  .sideCardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .articleSectionHeading {
    font-family: "Rubik";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    padding: 10px 0;
    border-bottom: 2px solid #dcdfe0;
    color: #394250;
  }
  .articleSectionHeading > span {
    padding: 13.9px 0;
  }
  .cardText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .cardHeading {
    font-family: "Lato";
    margin-top: 0;
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    font-size: 16px;
    letter-spacing: 0.16px;
  }
  .postInfo ul {
    gap: 10px;
    display: flex;
    align-items: center;
    margin-top: 0;
    gap: 30px;
  }
  .postInfo ul li:first-child {
    list-style: none !important;
    color: var(--Grey-20, #4C4B55);

/* B2_Mobile */
font-family: "Lato";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
  }
  .postInfo ul li {
    list-style: disc;
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
  }
  .cardTimeImage {
    margin-right: 8px;
  }
  .subArticle {
    border-bottom: 1px solid #eaeaea;
    padding: 10px 0;
  }
  .subarticleHeading {
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.2px;
  }
  .reform {
    padding: 15px 5px;
  }
  .timeContainer {
    display: flex;
    align-items: center;
    color: var(--Grey-20, #4C4B55);

/* B2_Mobile */
font-family: "Lato";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
  }
  .cardImageContainer {
    position: relative;
  }
  .cardImageContainer img {
    border-radius: 5px;
    width: 72px;
    height: 72px;
  }
  .cardImageContainer .postTags {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    gap: 10px;
  }
  .cardImageContainer .postTags .postTag {
    background: #fff;
    color: #1652c7;
    font-family: "Lato";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 20px;
    border-radius: 20px;
  }
  .eventsPagination {
    margin-bottom: 83px;
  }
  :root {
    --pagination-color: var(--primary-color);
    --pagination-font-family: "Lato";
    --pagination-font-size: 18px;
    --pagination-active-bg: var(--primary-color);
  }
  .prevButton {
    margin-right: auto;
    font-weight: 600;
  }
  .nextButton {
    margin-left: auto;
    font-weight: 600;
  }
  .homeHr{
background: #DCDFE0;
  background: var(--Stroke-Grey, #DCDFE0);
    height: 1px;
}
}
