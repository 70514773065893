.homearticles {
  padding: 15px 30px;
  margin-top: 20px;
}
.articlesHeading {
  font-family: "Rubik";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 54px */
  margin-bottom: 15px;
}
.main {
  display: flex;
  flex-wrap: wrap; /* Allow articles to wrap onto next line */
  justify-content: space-between;
}
.articleSection {
  width: calc(33.33% - 20px); /* Adjust width for margin or padding */
  margin-bottom: 20px; /* Add margin between articles */
  box-sizing: border-box; /* Ensure padding and border are included in the width calculation */
}
.articleSectionHeading {
  font-family: "Rubik";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  padding: 10px 0;
  border-bottom: 1px solid #DCDFE0;
  color: #394250;
  padding-bottom: 11px;

}
.articleSectionHeading > span{
    border-bottom: 2px solid var(--primary-color);
    padding: 13.9px 0;
}
.card{
  margin: 24px 0;
  padding-bottom: 10px;
  box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}
.cardText{
  padding: 5px 10px;
}
.cardImage{
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.cardHeading{
  font-family: "Lato";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 30px */
letter-spacing: 0.2px;
margin-top: 10px;
}
.cardTime{
  font-family: "Lato";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
letter-spacing: 0.16px;
display: flex;
align-items: center;
margin-top: 8px;
}
.cardTimeImage{
  margin-right: 8px;
}
.hr{
  border: 2px soild #EAEAEA;
}
.subArticle{
  border-top: 1px solid #EAEAEA;
  padding: 10px 0;
  cursor: pointer;
}
.subarticleHeading{
  font-family: "Lato";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 30px */
letter-spacing: 0.2px;
}

/* responsive for mobile, tablet and desktop */
@media (max-width: 768px) {
  .main {
    flex-direction: column;
  }
  .articleSection {
    width: 100%;
  }
  .articleSectionHeading {
    font-size: 20px;
  }
  .card {
   margin: 12px 0;
  }
  .cardHeading {
    font-size: 18px;
  }
  .cardTime {
    font-size: 14px;
  }
  .subarticleHeading {
    font-size: 18px;
  }
}
