.mainAlpha{
  border-bottom: 2px solid #EAEAEA;
  margin-bottom: 48px;
}
.alphabetical {
  padding: 36px 0;
  width: 80%;
}
.firstRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.secondRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.thirdRow {
  display: flex;
  justify-content: space-between;
}
.alphabet {
  padding: 2px 10px;
  border-radius: 4px;
  color: #26262c;
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.18px;
  width: 13px;
  display: flex;
  justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
}
.alphabetSelect {
  background-color: var(--primary-color);
  color: #fff;
}
@media (max-width : 680px) {
  .alphabetical{
    width: 100%;
  }
}