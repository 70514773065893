.upcomingcard{
   width: 416px;
    border-radius: 4px;
background: #FFF;
box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.06);
margin-bottom: 36px;
cursor: pointer;
}
.upcomingcard > img{
    object-fit: cover;
}
.cardImage{
    border-radius: 4px 4px 0 0 ;
    width: 100%;
}
.first{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 10px;
    padding: 4px;
}
.cardHeading{
    font-family: "Lato";
font-size: 20px;
font-style: normal;
font-weight: bolder;
line-height: 150%; /* 27px */
}
.cardDate{
    color: #1A97FF;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 24px */
letter-spacing: 0.16px;
display: flex;
align-items: center;
margin-bottom: 12px;
}
.cardDate > img{
    margin-right: 8px;
}
.text{
    padding-left: 20px;
}
.cardLocation{
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.16px;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    text-transform: capitalize;
}
.cardLocation > img{
    margin-right: 8px;
}
.shareButton{
    width: 32px;
height: 32px;
border-radius: 50px;
background-color: rgba(230, 238, 245, 0.60);
display: flex;
justify-content: center;
align-items: center;
margin-right: 20px;
}

/* responsive for mobile, tablet and desktop */
@media only screen and (max-width: 1300px) {
    .upcomingcard > img {
    object-fit: cover;
    width: 100% !important;
}
     .upcomingcard{
        width: 100% !important;
    }
}
@media only screen and (max-width: 992px) {
    .upcomingcard > img {
    object-fit: cover;
    width: 100% !important;
}
     .upcomingcard{
        width: 100% !important;
    }
}
@media only screen and (max-width: 768px) {
    .upcomingcard{
        width: 100%;
    }
    .cardImage{
        width: 100%;
        height: 200px;
    }
    .first{
    margin-bottom: 12px;
    padding-bottom: 0px;
    margin-top: 0;
    }
    .text{
       padding: 12px;
    padding-bottom: 26px;
    }
    .cardHeading{
        font-size: 16px;
    }
    .cardDate{
        font-size: 14px;
    }
    .cardLocation{
        font-size: 14px;
    }
    .shareButton{
        margin-right: 0;
    }
    .mobileShare{
        position: absolute;
    bottom: 10px;
    right: 12px;
    }
    .thumb{
        position: relative;
    }
}


