@import url("https://fonts.googleapis.com/css2?family=Lato&family=Rubik:wght@400;500;700&display=swap");

:root {
  --bg-color: white;
  --primary-color: #81298f;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap");

:root {
  /* fonts */
  --blog-body-text: "Lato";
  --header-text: "Rubik";
  --font-lato: "Lato";

  /* font sizes */
  --body-text-size: 16px;
  --blog-body-text-size: 20px;
  --header-text-size: 24px;
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --blog-title-size: 36px;

  /* Colors */
  --color-whitesmoke: #f1f2f2;
  --color-royalblue: #1652c7;
  --color-gray-100: #26262c;
  --color-gainsboro: #e4dee4;
  --color-white: #fff;
  --color-black: #000;
  --grey-20: #4c4b55;
  --color-darkslategray: #393737;

  /* Gaps */
  --gap-17xl: 36px;
  --gap-xl: 20px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-5xl: 24px;

  /* Paddings */
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-5xs: 8px;
  --padding-10xs: 3px;
  --padding-8xs: 5px;
  --padding-7xs: 6px;

  /* border radiuses */
  --br-9xs: 4px;
}
.pagination .active > .page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.pagination .page-link {
  color: black;
}
body {
  background: var(--bg-color);
  font-family: "Lato", sans-serif;
}
ul {
  list-style: none;
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  width: 0.5vw;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
.mainContainer {
  max-width: 1440px;
  margin: auto;
  box-sizing: border-box;
  padding: 0 60px;
}
.formValidation {
  position: relative;
}
.error {
  position: absolute;
  color: red !important;
  bottom: -30px;
  left: 0px;
}
.successMsg {
  position: absolute;
  color: green;
  bottom: -30px;
  left: 0px;
}
@media screen and (max-width: 1440px) {
  .mainContainer {
    padding: 0 30px;
  }
}
@media screen and (max-width: 968px) {
  .mainContainer {
    padding: 0 24px;
    width: 100%;
    box-sizing: border-box;
  }
}

/* .mainContainer */

@media screen and (max-width: 968px) {
  .mainContainer {
    padding: 0 24px;
    overflow: hidden;
  }
}
@media screen and (max-width: 680px) {
  .mainContainer {
    padding: 0 24px;
    overflow: hidden;
  }
  .emailArea {
    position: relative;
  }
  .error {
    position: absolute;
    color: red;
    bottom: -30px;
    left: 20px !important;
  }
}

.upCommingSwiper {
  padding: 30px 0;

  width: 100%;
}
.hr {
  background: #dcdfe0;
  height: 1px;
  border: none;
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
  height: 540px;
  background-size: cover;
  object-fit: cover;
}
.upcommingPrev {
  position: absolute;

  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #dcdfe0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.upcommingNext {
  position: absolute;

  right: 0;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #dcdfe0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.upcommingNext,
.upcommingPrev {
  position: absolute;
  top: 13px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0;
  border: revert-layer;
  z-index: 1;
}
.wrapIcon {
  width: 87%;
  position: absolute;
}
.upwrap {
  display: flex;
}
