.featuredThisWeek {
  width: 376px;
  color: var(--grey-10, #26262c);
  font-family: "Rubik";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 57.6px */
}

.policy {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 150%;
  font-weight: 500;
}
.category,
.tags {
  flex-direction: row;
}
.category {
  border-radius: var(--br-xl);
  background-color: var(--color-white);
  display: flex;
  padding: var(--padding-9xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
}
.tags {
  position: absolute;
  top: 213px;
  left: 12px;
  display: none;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.title {
  position: relative;
  line-height: 150%;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  width: 400px;
}

.footerChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-darkslategray);
  width: 4px;
  height: 4px;
  display: none;
}
.july122023 {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 150%;
  display: none;
}
.footerItem {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-darkslategray);
  width: 4px;
  height: 4px;
}
.timeIcon {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.timeArea,
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.footer {
  width: 400px;
  flex-wrap: wrap;
  gap: 16px;
  font-size: var(--body-text-size);
}
.container {
  gap: var(--gap-5xs);
  font-size: 20px;
  color: var(--color-black);
}
.blogCard,
.featuredPost,
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.blogCard {
  gap: var(--gap-base);
  font-size: var(--body-text-size);
  color: var(--color-royalblue);
  font-family: var(--body-text);
}
.featuredPost {
  position: relative;
  width: 100%;
  gap: 24px;
  text-align: left;
  font-size: 32px;
  color: #26262c;
  font-family: var(--font-rubik);
}
.author {
  color: #000;

  /* Body Text */
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
}
.blogCard > img{
  width: 400px;
}

.blogCard{
  cursor: pointer;
}

@media (max-width: 425px) {
  .blogCard > img{
    width: 342px;
  }
}