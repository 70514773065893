.eventdetailpage {
  padding: 25px 40px;
}
.heading {
  font-family: "Rubik";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 54px */
}
.subHeading {
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: 0.2px;
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text1 {
  color: var(--primary-color);
}

.tagFill1Icon{
  cursor: pointer;
}

.searchBar {
  padding: 12px 24px;
  display: flex;
  border-radius: 36px;
  background: #f6f6f6;
  width: 20vw;
}
.searchBar > input {
  outline: none;
  border: none;
  background-color: transparent;
  margin-left: 12px;
  width: 20vw;
}
.searchBar > input ::placeholder {
  color: var(--grey-20, #4c4b55);
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: 0.18px;
}

.main {
  display: flex;
}
.card {
  width: 50vw;
  margin-right: 20px;
}
.cardText {
  margin: 50px 0;
}
.overview {
  font-family: "Lato";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  margin-bottom: 24px;
}
.desc {
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 36px */
  letter-spacing: 0.2px;
}
.info {
  width: 50vw;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  height: fit-content;
}
.infoTitle {
  font-family: "Rubik";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.infoSubtitle {
  display: flex;
  align-items: center;
}
.infoSubtitle > img {
  margin: 0 24px;
  margin-top: 6px;
  width: 4px;
}
.infoSubtitle > .by {
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  margin-right: 8px;
}
.infoSubtitle:nth-child(2) {
  font-family: "Lato";
  color: #4c4b55;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 36px */
  letter-spacing: 0.2px;
}
.free {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.32px;
  padding: 8px 16px;
  border-radius: 4px;
  background: #f1f2f2;
}
.register {
  padding: 24px 0;
  border-bottom: 2px solid #eaeaea;
}
.register > button {
  width: auto;
  height: 48px;
  border-radius: 48px;
  border: none;
  outline: none;
  padding: 12px 24px;
  color: #fff;
  font-family: "Rubik";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.32px;
  background-color: #26262c;
  cursor: pointer;
}
.share {
  display: flex;
  padding: 24px 0;
  border-bottom: 2px solid #eaeaea;
  align-items: center;
}
.shareText {
  color: var(--grey-10, #26262c);
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 36px */
  letter-spacing: 0.2px;
  margin-right: 20px;
}
.share > img {
  margin-right: 20px;
  cursor: pointer;
}
.date {
  padding: 24px 0;
  border-bottom: 2px solid #eaeaea;
}
.dateTitle {
  color: var(--grey-10, #26262c);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  letter-spacing: 0.2px;
}
.date > p {
  font-family: "Lato";
  color: #4c4b55;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 36px */
  letter-spacing: 0.2px;
}
.location {
  padding: 24px 0;
}
.locationTitle {
  color: var(--grey-10, #26262c);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  letter-spacing: 0.2px;
}
.location > p {
  font-family: "Lato";
  color: #4c4b55;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 36px */
  letter-spacing: 0.2px;
}
.image img {
  width: 652px;
}

/* responsive for mobile, tablet and desktop */

@media (max-width: 1300px) {
  .image img {
    width: 400px;
  }
}
@media (max-width: 992px) {
  .image img {
    width: 100%;
  }
 
  .card {
    width: 100%;
  }
  .info {
    width: 100%;
  }
  .searchBar {
    width: 100%;
  }
  .searchBar > input {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .main {
    flex-direction: column;
  }
  .card {
    width: 100%;
  }
  .info {
    width: 100%;
  }
  .searchBar {
    width: 100%;
  }
  .searchBar > input {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .main {
    flex-direction: column;
  }
  .card {
    width: 100%;
  }
  .info {
    width: 92%;
  }
  .searchBar {
    width: 100%;
  }
  .searchBar > input {
    width: 100%;
  }
}
.frameGroup{
  display: flex;
  align-items: center;
}
.frameGroup img{
  display: flex;
  align-items: center;
  padding: 10px;
}