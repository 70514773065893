.cutHeading {
  font-family: "Rubik";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  width: 90%;
  letter-spacing: 0.9px;
  line-height: 120%;
  margin-top: 20px;
}
.p1 {
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28.8px */
  letter-spacing: 0.16px;
  margin: 15px 0;
}
.rightInput {
  display: flex;
  outline: none;
  padding: 4px;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  width: 312px;
}
.emailArea > input ::placeholder {
  color: #969DAB;
}
.rightButton {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #26262C;
  color: white;
  cursor: pointer;
  font-family: "Rubik";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.32px;
  width: fit-content;
}
.cutButton {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.sideButtonText {
  color: var(--grey-20, #4c4b55);
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 16px;
  letter-spacing: 0.14px;
}
