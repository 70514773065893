.policy {
  padding: 25px 40px;
}
.policyHeading {
  font-family: "Rubik";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 8px;
}
.date {
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  margin-bottom: 24px;
}
.right {
  width: 70%;
  margin-right: 48px;
}
.left {
  width: 30%;
}
.leftContainer {
  position: sticky;
  top: 80px;
  padding-top: 25px;
}
.subText {
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  margin-bottom: 48px;
}
.item {
  margin-bottom: 64px;
}
.title {
  font-family: "Rubik";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-bottom: 12px;
  border-bottom: 1px solid #dcdfe0;
}
.text {
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
  letter-spacing: 0.16px;
  margin-top: 12px;
}
.subItemTitle {
  margin-top: 15px;
  font-family: "Rubik";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.subItemText{
  white-space: pre-wrap;
  text-align: justify;
}
.tableHeading {
  margin-bottom: 24px;
  font-family: "Rubik";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.main {
  display: flex;
}
.tableItem {
  padding: 10px 12px;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  margin-bottom: 8px;
  border-radius: 4px;
}
.tableItem > a {
  text-decoration: none;
  color: #000;
}
.custMt {
  padding-top: 100px;
}
.noMt {
  padding-top: 0px;
}
@media (max-width : 1010px) {
  .right{
    width: 100%;
    margin-right: 0px;
  }
  .left{
    display: none;
  }
  .policy{
    padding: 24px;
  }
  .title{
    font-size: 20px;
  }
  .subItemTitle{
    font-size: 16px;
  }
}
.mobileTable{
  border: 2px solid #DCDFE0;
  padding: 12px;
}
.mobileTableTitle{
  font-family: "Rubik";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 150%;
margin-bottom: 16px;
}
.mobileTableItem{
  font-family: "Lato";
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 24px */
letter-spacing: 0.16px;
margin: 12px 0;
}
.mobileTableItem span{
  text-decoration: underline;
}
.mobileTableMain{
  padding-bottom: 48px;
  margin-bottom: 48px;
  border-bottom: 2px solid #E4DEE4;
}