.sPopularCard{
    border-bottom: 2px solid #EAEAEA;
    padding: 16px 0;
}
.heading{
    font-family: "Lato";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 27px */
letter-spacing: 0.18px;
}
.text{
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-family: "Lato";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
letter-spacing: 0.16px;
color: #25726D;
}
.time{
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: #4C4B55;
}
.time > img{
    margin-right: 8px;
}
.textElem{
    display: flex;
    align-items: center;
    color: var(--primary-color);
    margin-right: 10px;
}
.textElem > img{
    margin-right: 8px;
}