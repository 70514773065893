.postHeading{
  color: #26262C;
  font-family: "Rubik";
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: 180%;
margin-bottom: 24px;
}
.postTitle{
  color: #26262C;
font-family: "Lato";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 150%;
letter-spacing: 0.18px;
margin-bottom: 12px;
}
.post {
  cursor: pointer;
  padding: 16px 0;
  border-bottom: 1px solid #eaeaea;
}
.postInfo,
.time,
.tag {
  display: flex;
  align-items: center;
  gap: 5px;
}
.tag > p {
  margin: 0 10px;
  margin-right: 8px;
  color: #81298F;
}
.postInfo > p {
  color: #81298F;
  display: flex;
  align-items: center;
}
.postInfo > p > img{
  margin: 0 8px;
  width: 5px;
  padding-top: 3px;
}
/* .time{
  margin-left: 24px;
} */
.time > img {
  margin-right: 8px;
}
.time > p {
  color: #4c4b55;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
}
