.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
form {
    display: flex;
    flex-direction: column;
    
    align-items: stretch;

  
}
.inputGroup {
   margin-bottom: 30px;
    margin-left: 0;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

label {
  font-weight: 500;
  display: block;
  font-size: 21px;
}

.select,
.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.submitButton {
  background-color: #812b8e;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #6a1d72;
}
/* responsive for mobile devices */
@media (max-width: 768px) {
  h1 {
    font-size: 20px;
}
label {
    font-weight: bold;
    display: block;
    font-size: 15px;
}
  .inputGroup {
    margin-top: 20px;
  }
  .submitButton {
    margin-top: 20px;
  }
}

.title{
  font-weight: 500;
}



