.search{
    padding: 25px 40px;
}
.searchHeading{
    font-family: "Rubik";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 150%;
margin-bottom: 36px;
}
.main{
    display: flex;
}
.article{
    width: 65vw;
    margin-right: 40px;
}
.popular{
    width: 35vw;
    margin-top: -18px;
}
.articleHeading{
    font-family: "Rubik";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}

.border{
    border-bottom: 2px solid #81298F;
    padding: 0 3px;
    padding-bottom: 4px;
    padding-bottom: 3px;
    margin-right:24px;
}
.popularTitle{
    color: var(--grey-10, #26262C);
font-family: Rubik;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 180%; /* 57.6px */
}
.prevButton {
    margin-right: auto;
    font-weight: 600;
}
.nextButton {
    margin-left: auto;
    font-weight: 600;
}
.pagination{
    margin-top: 36px;
}
.glossary{
    margin-top: 90px;
}
.glossaryHeading{
    font-family: "Rubik";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    border-bottom: 1px solid #DCDFE0;
}
.glossaryHeadingText{
    border-bottom: 2px solid #81298F;
    padding-bottom: 4px;
    padding-right: 5px;
}
.newsletter{
    margin-top: 209px;
}
.mobleH span{
    font-size:20px;
}
.mobleH {
    font-size:20px;
    border-bottom: 1px solid #DCDFE0;
}
.notActive{
    color: #969DAB;
    padding-bottom: 3px;
    margin-right: 24px;
}

/* responsive for mobile , tabs and pc */
@media only screen and (max-width: 768px) {
    .search{
        padding: 25px 20px;
    }
    .article{
        width: 100%;
        margin-right: 0;
    }
    .popular{
        width: 100%;
        margin-top: 0;
    }
    .popularTitle{
        font-size: 24px;
    }
    .glossary{
        margin-top: -45px;
    }
    .newsletter{
        margin-top: 50px;
    }
    .mobleH span{
        font-size: 20px;
    }
    .searchHeading{
        font-size: 24px;
    }
    .articleHeading{
        font-size: 20px;
        margin-bottom: 36px;
    }
    .glossaryHeading{
        font-size: 20px;
    }
    .glossaryHeadingText{
        font-size: 20px;
    }
    .notActive{
        font-size: 20px;
    }
}