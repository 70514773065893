.homeupcoming {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
}
.top {
  display: flex;
  align-items: center;
}
.heading {
  font-family: "Rubik";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  margin: 25px 0;
}
.headingView {
  color: var(--primary-color);
  font-family: "Rubik";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  transform: translateX(36px);
  text-decoration: none;
}
.headingView > img{
    margin-left: 5px;
}
.cards{
  display: flex;
  justify-content: space-between;
}

/* responsive for mobile, tablet and desktop */
@media (max-width: 992px) {
  .homeupcoming {
    padding: 0 30px;
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .heading {
    font-family: "Rubik";
    font-size: 24px;
    font-style: normal;
    font-weight: bolder;
    margin: 25px 0;
  }
  .headingView {
    color: var(--primary-color);
    font-family: "Rubik";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    transform: translateX(36px);
    text-decoration: none;
  }
  .headingView > img{
    margin-left: 5px;
  }
  .cards{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .homeupcoming {
    padding: 0 30px;
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .heading {
    font-family: "Rubik";
    font-size: 24px;
    font-style: normal;
    font-weight: bolder;
    margin: 25px 0;
  }
  .headingView {
    color: var(--primary-color);
    font-family: "Rubik";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    transform: translateX(36px);
    text-decoration: none;
  }
  .headingView > img{
    margin-left: 5px;
  }
  .cards{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 576px) {
  .homeupcoming {
    padding: 0 30px;
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .heading {
    font-family: "Rubik";
    font-size: 24px;
    font-style: normal;
    font-weight: bolder;
    margin: 25px 0;
  }
  .headingView {
    color: var(--primary-color);
    font-family: "Rubik";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    transform: translateX(36px);
    text-decoration: none;
  }
  .headingView > img{
    margin-left: 5px;
  }
  .cards{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 480px) {
  .homeupcoming {
    padding: 0 30px;
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .heading {
  font-family: "Rubik";
    font-size: 24px;
    font-style: normal;
    font-weight: bolder;
    margin: 0;
    margin-bottom: 36px;
  }
  .headingView {
        color: var(--primary-color);
    font-family: "Rubik";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    transform: translateX(36px);
    text-decoration: none;
    width: 65vw;
  }
  .headingView > img{
    margin-left: 5px;
  }
  .cards{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
