.mainContainer {
  background-color: var(--bg-color);
  position: sticky;
  top: 0;
  z-index: 999999999;
  display: flex;
  justify-content: space-between;
}
.mainContainer > nav {
  margin: 0 auto;
  width: 1440px;
  box-sizing: border-box;
  padding: 0 60px;
}
.brBottom {
  border-bottom: 1px solid #dcdfe0;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  background-color: #f0f0f0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}
.navContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 112px;
  position: sticky;
  padding: 0 0px;
  top: 0;
}
.navLogo {
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navLinks {
  display: flex;
  list-style: none;
}
.navLinks a {
  display: block;
  padding: 10px;
  text-decoration: none;
}

.searchBar {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navSearch {
  width: 450px;
  height: 48px;
  border-radius: 48px;
  border: none;
  outline: none;
  padding-left: 5px;
  background-color: #f6f6f6;
  position: relative;
}
.navSearch input {
  width: 100%;
  height: 48px;
  border-radius: 48px;
  border: none;
  outline: none;
  padding-left: 48px;
  background-color: #f6f6f6;
}
.navSearch input::placeholder {
  color: #999999;
  color: var(--disabled-text-grey, #969dab);

  /* Body Text */
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
}
.navSearch img {
  position: absolute;
  left: 24px;
  top: 16px;
  width: 15px;
  height: 15px;
  line-height: 48px;
  text-align: center;
}

.navSubscribe {
  margin-right: 0;
}

.navSubscribe button {
  width: auto;
  height: 48px;
  border-radius: 48px;
  border: none;
  outline: none;
  padding: 12px 24px;
  color: #fff;
  font-family: "Rubik";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.32px;
  background-color: #26262c;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 9999;
}

.dropbtn {
  display: block;
  padding: 10px;
  text-decoration: none;
}

.dropbtn i {
  margin-left: 5px;
}

.dropdownContent {
  position: absolute;
  width: 272px;
  left: 0;
  top: 100%;
  display: none;
  visibility: hidden;
  z-index: 1;
  background: #fff;
  min-height: min-content;
  border-radius: 4px;
  background: #fff;

  /* Card drop shadow */
  box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.06);
}
.dropdownContent li {
  display: block;
  list-style: none;
  height: 48px;
}
.dropdownContent li a {
  display: block;
  text-decoration: none;
  color: var(--Grey-20, #4c4b55);
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  letter-spacing: 0.18px;
}
.dropdown:hover .dropdownContent {
  display: block;
  visibility: visible;
}
.dropdown:active .dropbtn {
  background-color: #edf2f7;
}
.dropdownContent li:hover {
  background-color: #edf2f7;
}
.navLinks a {
  display: block;
  padding: 10px;
  margin: 0px 10px;
  color: #000;
  font-family: "Rubik";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.36px;
  text-decoration: none;
}

.navLeft {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 50%;
}
.logo_b {
  width: 38px;
  margin-right: -12px;
  margin-left: 0;
}
.logo_name {
  width: 124px;
}
.mobileMenu {
  display: none;
}
.mobileMenu > .mobileMenuIcon {
  cursor: pointer;
}

.mobileNavBar {
  display: none;
}
.navLinks>li:hover li>a {
}
.dropdown:hover .navLinks>li>a>div{
  position: relative;
  display: inline-block;
  z-index: 9999;
  color:#81298F;
}
/* media queries Breakpoints */
@media screen and (max-width: 1440px) {
  .mainContainer > nav {
    margin: 0 auto;
    width: 1440px;
    box-sizing: border-box;
    padding: 0 30px;
  }
}
@media screen and (max-width: 1400px) {
  .searchBar {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .navLeft {
    width: 60%;
  }
  .searchBar {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .navSearch {
    width: 80%;
    height: 48px;
    border-radius: 48px;
    border: none;
    outline: none;
    padding-left: 5px;
    background-color: #f6f6f6;
    position: relative;
  }
  .navSearch input {
    height: 48px;
    border-radius: 48px;
    border: none;
    outline: none;
    padding-left: 48px;
    background-color: #f6f6f6;
  }
  .navLogo {
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 1200px) {
  .mainContainer > nav {
    margin: 0 auto;
    width: 100%;
    padding: 0 24px;
  }
  .searchBar {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .navLogo {
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

/* for tab view */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .mainContainer > nav {
    margin: 0 auto;
    width: 100%;
    padding: 0 24px;
  }
  input[type="text"]::placeholder {
    font-size: 14px;
  }
  .navContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    position: sticky;
    top: 0;
    width: 100vw;
    align-content: flex-start;
  }
  .navLeft {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .navSearch {
    max-width: 300px;
    width: 30px;
    padding-left: 1px;
  }
  .navSearch input {
    width: 100%;
  }

  .navLinks a {
    display: block;
    padding: 0px;
    margin: 0px 10px;
    color: #000;
    font-family: "Rubik";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.36px;
    text-decoration: none;
  }

  .navSearch img {
    left: 18px;
    top: 16px;
  }
  .searchBar {
    display: flex;
    gap: 60px;
    width: fit-content;
    justify-content: flex-end;
  }

  .navContainer {
    flex-direction: column;
    width: 100%;
  }

  .navLeft {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
  }
  .navLogo {
    padding-left: 0px;
  }
  .navLinks {
    display: none;
  }
  .navSearch {
    display: none;
    width: fit-content;
    padding-left: 0;
  }
  .navSubscribe {
    display: none;
  }
  .mobileMenu {
    display: block;
    padding-right: 0px;
  }
  .mobileNavBar {
    display: block;
    width: 100%;
    position: relative;
  }
  .mobilenav {
    display: block;
    width: 95%;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;
    background-color: #fff;
    padding: 20px;

    /* Card drop shadow */
    box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.06);
    /* slide donw animation */
    animation: slideDown 0.5s ease-in-out;
  }
  /* slide donw animation */
  @keyframes slideDown {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .mobileNavBar > .mobileNavSearch {
    margin: 0 20px;
    background-color: rgba(246, 246, 246, 1);
    padding: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .mobileNavSearch > img {
    margin-right: 12px;
  }

  .mobileNavSearch > input {
    border: none;
    background: none;
    outline: none;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
    width: 100%;
  }

  .mobileNavSearch > input::placeholder {
    color: #15181b;
  }
}

/* for mobile view */
@media screen and (min-width: 1px) and (max-width: 768px) {
  @keyframes slideDown {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  .navLinks {
    display: none;
  }
  .mobilenav {
    padding: 20px;
    padding-left:5px;
    animation: slideDown 0.5s ease-in-out;
  }
  .mainContainer {
    background-color: var(--bg-color);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    padding-bottom: 0;
  }
  .searchBar {
    display: none;
  }
  .navSearch {
    display: none;
  }
  .navSubscribe {
    position: absolute;
    right: 0;
    top: 112px;
    z-index: 1;
  }
  .navContainer {
    height: auto;
    align-items: center;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    align-content: flex-start;
    padding: 0 0px;
  }
  .navLeft {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
  }
  .mobileMenu {
    display: block;
    padding-right: 0px;
  }
  .navSubscribe {
    display: none;
  }
  .mobileNavBar {
    display: block;
    width: 100%;
  }

  .mobileNavBar > .mobileNavSearch {
    margin: 0 0px;
    background-color: rgba(246, 246, 246, 1);
    padding: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .mobileNavSearch > img {
    margin-right: 12px;
  }

  .mobileNavSearch > input {
    border: none;
    background: none;
    outline: none;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
    width: 100%;
  }

  .mobileNavSearch > input::placeholder {
    color: #15181b;
  }
}
.caretDropDown {
  display: flex;
  align-items: center;
}
.caretDropDown > img {
  margin-left: 5px;
}
.caretImage {
  transform: rotate(0);
}
.caretImage {
  transform: rotate(0);
}

@media (max-width: 768px) {
  .menucontainer1 {
    width: 100%;
    gap: 36px;
  }
}
.dropdown:hover a>div{
  color:#812B8E;
}
